import React, {Fragment, useState, useEffect } from 'react';
import { Table, Row, Col, Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFileDownload} from 'react-icons/fa';

import SelectInput from "../selectInput";

import TablaInf2_Detallado from './tablaInf2_detallado'; //REsumen; Cantidad de lotes y proyectos

import TablaInf2_Madre from './tablaInf2_madre'; // Todos los campos --- hide
import TablaInf2_Seleccionados from './tablaInf2_seleccionados'; // Campos seleccionados ---> Es la visible
import TablaInf2_Analitico from './tablaInf2_analitico'; // analitico ---hide
import TablaInf2_Actuaciones from './tablaInf2_actuaciones'; // actuaciones ---hide

import Map_View_Expedientes from '../map_view_expedientes';

import {optionsRP2_Todos, optionsPrograma_Todos, optionsEstado_Todos, optionsTipo_Todos, optionsRegiones_todos, optionsResponsable_Todos } from "../../datos/options";

//CAMBIO 27jun24: importar y no declararlas en este archivo
import { columnas, columnasMadre, columnasAnalitico, columnasActuaciones } from "../../datos/columns"

const SelectInf_Detallado = ({name}) => {

	const nameLogin = name;

	const [datosS, setDatos] = useState(null);// ESTADO para recibir datos de la consulta
	const [datosGEO, setDatosGEO] = useState(null);// ESTADO para construir geojson

	const [exportGEOJSON, setExportGEOJSON ] = useState(null);

	//const [inputDisable, setInputDisable] = useState(false); //ADD 27-09-2022
	const [datosOriginal, setDatosOriginales] = useState(null);
	const [datosMadre, setDatosMadre] = useState(null);
	const [datos_Inf2_finalizadosTotal, setdatos_Inf2_finalizadosTotal]= useState(null); //ADD 27-09-2022
	const [datos_Inf2_Analitico, setdatos_Inf2_Analitico]= useState(null);
	const [datos_Inf2_Actuaciones, setdatos_Inf2_Actuaciones]= useState(null);

	const [filtro, setFiltro] = useState({ //PROBAR con 2 filtros
		prioritario:"TODOS",
		programa:"",
		partido:0,
		tipo:"",
		estado: "AFECTADO CON RESOLUCIÓN",//Poner un valor por default
		datos_05_responsable: "",
		region: ""
	});
	
	const {prioritario, programa, partido, tipo, estado, datos_05_responsable, region } = filtro;	

	const [showDraw, setShowDraw] = useState(false);//cambiar luego a false para que esté apagada por default

	const handleClose = () => {		
		setShowDraw(false);		
	};

	const guardarEditRegistro = () => {
		setShowDraw(false);
	}

	var buttonClick = (e)=>{
		e.preventDefault();
		
		setShowDraw(true);			
	}

	//24-09-2022 - agregado: poner fecha por default
	const [startDate, setStartDate] = useState(new Date('2014-01-01'));
  	const [endDate, setEndDate] = useState(new Date('2025-12-31'));

	const [ultimoDia, setUltimoDia] = useState(new Date('2025-12-31'));

	/*REVISAR
	useEffect(()=>{
		//var x = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
		var x = new Date(startDate)
		x.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
		setStartDate(x);
	}, [startDate]);*/
	

	useEffect(()=>{
		//var y = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
		let y = new Date(endDate);
		y.setMinutes(y.getMinutes() + y.getTimezoneOffset())
		setUltimoDia(y);
	}, [endDate]);

	let _fechaInicio = null;
	if (startDate === null) {
		_fechaInicio = null;
	} else if (startDate !== null || startDate !== ""){
		_fechaInicio = new Date(startDate);
		_fechaInicio.setMinutes(_fechaInicio.getMinutes() + _fechaInicio.getTimezoneOffset())
	}

	const url = `/api/api_serv/reportes/reporte2_Detalle/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&start=${startDate}&end=${ultimoDia}&region=${region}&datos_05_responsable=${datos_05_responsable}`;
	const urlRP2 = `/api/api_serv/reportes/reporte2_Total/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&start=${startDate}&end=${ultimoDia}&region=${region}&datos_05_responsable=${datos_05_responsable}`;
	
	const url_Madre = `/api/api_serv/reportes/reporte2_Madre/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&start=${startDate}&end=${ultimoDia}&region=${region}&datos_05_responsable=${datos_05_responsable}`;

	const urlRepo_Analitico = `/api/api_serv/reportes/reporte2_Analitico/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&start=${startDate}&end=${ultimoDia}&region=${region}&datos_05_responsable=${datos_05_responsable}`;
	const urlRepo2_Actuaciones = `/api/api_serv/reportes/reporte2_Actuaciones/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&start=${startDate}&end=${ultimoDia}&region=${region}&datos_05_responsable=${datos_05_responsable}`;

	//****  IMPORTANTE: cuando cambia filtro o rango fechas, llamar functions
	useEffect(() => {
		getDatos(url);
		getdatos_Inf2_finalizadosTotal(urlRP2);
		//getdatos_Inf2_analitico(urlRepo_Analitico);
	},[filtro, startDate, ultimoDia]); // POR AHORA UNA SOLA VEZ, //NO  ---> SOLO CUANDO CAMBIO EL estado LLAMAR NUEVAMENTE // COMENTAR filtro.estado
	
	const [search, setSearch] = useState(""); //posible borrar
	const [searchPartido, setSearchPartido] = useState(0);
	const [searchEstado, setSearchEstado] = useState("");
	const [searchPrograma, setSearchPrograma] = useState("");
	const [searchTipo, setSearchTipo] = useState("");
	const [searchRegion, setSearchRegion] = useState("");
	
	//**consultar los datos para el reporte2
	const getDatos = async (urlM) => {

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(urlM, { headers })
		.then(res => res.json())
		.then(res => {
			setDatos(res)
			//setDatosMadre(res)
			setDatosOriginales(res)
			setDatosGEO(res)
		})
		//.then(res => setDatosOriginales(res))
		.catch(err => console.log(err));
	}

	const getDatosMadre = async (urlM) => { // para table madre

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(urlM, { headers })
		.then(res => res.json())
		.then(res => {
			setDatosMadre(res)			
		})
		.catch(err => console.log(err));
	}

	const getdatos_Inf2_finalizadosTotal = async (urlRP2) => {

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token };
		const res = await fetch(urlRP2,{ headers })
		.then(res => res.json())
		.then(res => setdatos_Inf2_finalizadosTotal(res))
		.catch(err => console.log(err));
	}

	//add 27-dic-2022 getdatos_Inf2_analitico
	const getdatos_Inf2_analitico = async (url) => {

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token };

		const res = await fetch(url,{ headers })
		.then(res => res.json())
		.then(res => setdatos_Inf2_Analitico(res))
		.catch(err => console.log(err));
	}

	const getdatos_Inf2_Actuaciones = async (url) => {

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token };

		const res = await fetch(url,{ headers })
		.then(res => res.json())
		.then(res => setdatos_Inf2_Actuaciones(res))
		.catch(err => console.log(err));
	}

	//*** NUEVA (28-jun-24) para cargar y descargar la tabla madre ***
	const loadMadre = () => {
		getDatosMadre(url_Madre)	//change url X url_Madre
	}

	useEffect(() => {
		if(datosMadre !== null){
			document.getElementById("test-table-xls-button").click()
		}
	}, [datosMadre])
	//------cierra

	//**NUEVA (28-jun-24) ya están cargados los datos, solo descargar la tabla campos seleccionados

	const loadSeleccionados = () => {
		if(datosS !== null){
			document.getElementById("uno-table-xls-button").click()
		}
	}
	// -----cierra

	//** NUEVA (28-jun-24) para cargar y descargar la tabla reporte analitico ***
	const loadAnalitico = () => {
		getdatos_Inf2_analitico(urlRepo_Analitico);				
	}

	useEffect(() => {		
		if(datos_Inf2_Analitico !== null ){
			document.getElementById("analitico-table-xls-button").click()
		}
    }, [datos_Inf2_Analitico]);
	// ----- cierra

	//NUEVA (28-jun-24) para cargar y descargar la tabla estado actuaciones ***
	const loadActuaciones = () => {
		getdatos_Inf2_Actuaciones(urlRepo2_Actuaciones);
	}

	useEffect(() => {
		if(datos_Inf2_Actuaciones !==null ){
			document.getElementById("actuaciones-table-xls-button").click()			
		}  
	}, [datos_Inf2_Actuaciones])
	// ---- cierra

	//**abre Estados y effects - Generacion de geojson descarga -05agos2023- OSO*/
	useEffect(()=>{
		if(datosGEO !== null){ //solo si NO es null
			//console.log(datosGEO);
			const respuesta= datosGEO.map((element, index) => {
				
				let geomA="";
				
				if(element.geom_geojson === null || element.geom_geojson === "{\"type\":\"GeometryCollection\",\"geometries\":[]}" || element.geom_geojson === '{"type":"GeometryCollection","geometries":[]}'){
					//console.log("NO TIENE DATOS GEO");
					//return {"type":"GeometryCollection", "properties": {},"geometries":[]};
					geomA = 'sin geom'
				} else {
					//console.log("TIENE DATOS GEO");
					geomA = 'geom'
				}
				//console.log(index, element.geom_geojson)
				//console.log(index, element.geom_geojson.geometries); 

				let geom_multi = JSON.parse(element.geom_multi);// no usar geom_geojson (viene como GeometryCollection)
				//console.log(geom_multi);

				let aliasA = element.nombre_proyecto; //ALIAS
				let expedienteA = element.expediente;
				let estadoA = element.estado;
				let programaA = element.programa;
				let tipoA = element.tipo;
				let partidoA= element.partido_nombre;
				let cantLotesA = element.cantidad_lotes;				
				let cantLotesAdjA = element.cantidad_lotes_adjudicables;
				let porcLotesMunA = element.porc_lotes_municipales;
				let lotesAdqMunA = element.lotes_adquiridos_municipio;
				let id_10AN = element.sistema_agua;
				let id_11AN = element.eliminacion_excretas;
				let nomenclaSintesisA = element.datos_18_sintesis_nomenclatura;
				/*let id_12AN = element.año_emision;
				let id_13AN = element.periodo;*/
				let id_14AN = element.region ;				

				let id_1CSP;
				let id_2CSP;
				let id_3CSP;
				let id_4CSP;//agregar 6 nuevas columnas - 12jul2023
				let id_5CSP;
				let id_8CSP;
				let id_9CSP;
				let id_10CSP;
				let id_13CSP;
				let id_14CSP;
				let id_15CSP;
				let id_18CSP;

				let array = element.array_csp;
				array.map((item, index)=>{
					id_1CSP = item.csp_1_tamaño_medio_lotes_residenciales;
					id_2CSP = item.csp_2_actor_encargado_obras_infra;
					id_3CSP = item.csp_3_financiamiento_publico_obras_infra;
					id_4CSP = item.csp_4_jurisdiccion_infra;
					id_5CSP = item.csp_5_organismo_financiamiento_infra;
					id_8CSP = item.csp_8_financiamiento_publico_obras_viv
					id_9CSP = item.csp_9_jurisdiccion_viv;
					id_10CSP = item.csp_10_organismo_financiamiento_viv;
					id_13CSP = item.csp_13_financiamiento_publico_obras_equip;
					id_14CSP = item.csp_14_jurisdiccion_equip;
					id_15CSP = item.csp_15_organismo_financiamiento_equip;
					id_18CSP = item.csp_18_pl_mensura_estado;
				});				

				let propiedades =  {
					alias: `${aliasA}`,
					expediente: `${expedienteA}`,
					programa: `${programaA}`,
					tipo: `${tipoA}`, 
					estado: `${estadoA}`, 
					partido: `${partidoA}`,
					cant_lotes: `${cantLotesA}`,
					cant_lotes_adjudicables : `${cantLotesAdjA}`,
					porc_lotes_municipales : `${porcLotesMunA}`,
					lotes_adquiridos_municipio : `${lotesAdqMunA}`,
					sistema_de_agua : `${id_10AN}`,
					eliminación_de_excretas : `${id_11AN}`,
					datos_catastrales_dominiales_síntesis : `${nomenclaSintesisA}`,
					//año_emision : `${id_12AN}`,
					//periodo: `${id_13AN}`,
					tamaño_medio_lotes_residenciales: `${id_1CSP}`,
					actor_encargado_de_las_obras: `${id_2CSP}`,
					financ_público_obras_infra: `${id_3CSP}`,
					jurisdiccion_financ_público_obras_infra: `${id_4CSP}`,
					organismo_financ_público_obras_infra: `${id_5CSP}`,
					financ_público_obras_viviendas: `${id_8CSP}`,
					jurisdiccion_fpov: `${id_9CSP}`,
					organismo_fpov: `${id_10CSP}`,
					financ_público_obras_equip_comunitario: `${id_13CSP}`,
					jurisdiccion_fpoec: `${id_14CSP}`,
					organismo_fpoec: `${id_15CSP}`,					
					plano_estado_de_la_tramitación : `${id_18CSP}`,	
					region: `${id_14AN}`,
					geom: `${geomA}`
				};
				
				//REVISAR asignacion (4-agos-2023)
				//Object.assign(objA, geom_multi)                
				
				let features = {
					"type": "Feature",
					"properties": propiedades,
					"geometry": geom_multi
				}
				return features;					
				//return objA;                
				//}//cierra el if           
			});
			//console.log("RESPUESTA------>", respuesta);
			
			const nuevoG = {
				"type": "FeatureCollection",
				"features": respuesta
			};
			//nuevoG.features.push(respuesta);
			//console.log(nuevoG);			

			setExportGEOJSON(nuevoG)
		} //cierra if datosS
	}, [datosGEO])

	const exportData = () => {
		const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
			JSON.stringify(exportGEOJSON) //data
		)}`;
		const link = document.createElement("a");
		link.href = jsonString;
		link.download = "generación_de_suelo_urbano.geojson";
	
		link.click();
	};
	//**cierra generacion de geojson */

	/*const [arrayBusqueda, setArrayBusqueda]= useState({
		"expB" : "",
		"partidoB" : 0,
		"estadoB" : "",
		"aliasB" : "",
		"programaB" : "",
		"tipoB" : "",
		//"titularB" : "",
	});
	//console.log(arrayBusqueda);*/ /*POR AHORA NO USAR*/

	//**SELECCIONAR campos tabla madre***

	//const [municipioMadre, setMunicipioMadre] = useState("");

	//activo: true son los que mostraria inicialmente.
	/*const [camposInicial, setCamposInicial] = useState([
		{label:'Partido', activo:'true', campo:'partido_nombre'},
		{label:'Expediente', activo:'true', campo:'expediente'},
		{label:'Estado', activo:'false', campo:'estado'},
		{label:'Alias', activo:'true', campo:'nombre_proyecto'},
		{label:'Programa', activo:'false', campo:'programa'},
		{label:'Tipo', activo:'false', campo:'tipo'},
		{label:'Titular', activo:'false', campo:'titular_tierra'},
		{label:'Cantidad lotes', activo:'false', campo:'cantidad_lotes'},
		{label:'Lotes captados municipio', activo:'false', campo:'lotes_adquiridos_municipio'},
		{label:'Nomenclatura catastral', activo:'false', campo:'datos_18_sintesis_nomenclatura'},
	]);*/

	/*const [columnasMadre, setColumnasMadre] = useState([
		"Id_Exp","Alias", "Expediente","Fecha de carga","Fecha de caratulación","Responsable","Prioritario","Programa","Estado","Tipo",
		"Partido","Localidad","Cant. de lotes","Cant. de lotes adjudicables","Porc. lotes municipales","Lotes adquiridos por el municipio",
		"Sistema de agua","Eliminación de excretas",
		//ACA TODA LA NOMENCLATURA
		"Datos catastrales y dominiales - Síntesis",
		"Titular de la tierra","Plano oficial",
		//LOCALIZACION
		"Area","Zonificación","Nro ordenanza vigente","Decreto promulgatorio",
		"Observaciones generales",
		//Pestaña Tramitaciones
		//ETAPA 1
		"Nota solicitud adhesión al programa","Informe urbanístico municipal","Certif. de no inundabilidad del predio",
		"Anteproyecto urbano","Título de propiedad y/o datos de inscripción dominial",
		//Tramitaciones de la DPPH
		"Informe de dominio","Anotaciones personales","Admisibilidad inicial","Prefactibilidad urbanística","Prefactibilidad hidráulica y permisos - ADA",
		//ETAPA 2
		"Memoria descriptiva de las obras a ejecutar","Prefactibilidad de energía eléctrica","Prefactibilidad de agua potable por red",
		"Prefactibilidad de red cloacal","Factibilidad apertura de calles","Acta constitutiva persona jurídica",
		"Certificado de vigencia de Persona Jurídica","Plano de mensura y división","Convenio urbanístico","Ordenanza municipal",
		"Memoria descriptiva del procedimiento de adjudicación", "Proyecto hidráulico",
		"Certificado técnico urbanístico","Resolución de afectación","Visado de plano",
		"Observaciones internas", "Observaciones para reporte al municipio",
		//PREFACTIBILIDAD HIDRÁULICA
		"Tramitación ADA","Fecha de envío","Expediente ADA","Fecha de emisión","Observaciones tramitación ADA",
		//RESOLUCIÓN
		"Tramitación Resolución","Fecha de envío","Fecha de emisión","Número especial",//T135
		"Observaciones tramitación resolución",
		//Visado
		"Tramitación visado","Fecha de visado",//T138
		"Fecha de entrega",//T139
		"Observaciones tramitación visado",//T140
		//**PESTAÑA 3 - CSP
		"Tamaño medio de lotes residenciales",
		"Actor encargado de las obras","Financiamiento público de las obras de infraestructura",
		"Jurisdicción","Organismo de financiamiento","Fecha de control","Descripción obras de infraestructura financiadas",
		//Financiamiento de viviendas
		"Financiamiento público de viviendas",
		"Jurisdicción","Organismo de financiamiento","Fecha de control","Descripción obras de infraestructura financiadas",
		//Financiamiento de Equipamientos Comunitarios
		"Financiamiento público de equipamientos comunitarios",
		"Jurisdicción","Organismo de financiamiento","Fecha de control","Descripción obras de infraestructura financiadas",//17CSP
		//Plano de mensura y división
		"Estado de la tramitación","Número de plano","Orden","Año","Fecha",
		//Registración
		"Legajo","Folio","Fecha",
		"Fecha de control","Observaciones sobre el seguimiento de los proyectos",
		"Región"
	]);*/

	/*const crearHeaderMadre = (columnas)=>{
		//console.log(columnas);
		if(columnasMadre !== null){
			return columnas.map((columna, index) => {
				return <th key={index} >{columna}</th>
			});
		}
	}*/

	/*const [columnasAnalitico, setColumnasAnalitico] = useState([
		"Alias", "Expediente","Programa","Tipo","Partido","Cant. de lotes","Cant. de lotes adjudicables","Porc. lotes municipales",
		"Lotes adquiridos por el municipio", "Datos catastrales y dominiales - Síntesis",
		"Sistema de agua","Eliminación de excretas","Año de emisión","Periodo",
		"Tamaño medio de lotes residenciales","Actor encargado de las obras",
		"Financiamiento público de las obras de infraestructura",
		"Jurisdicción","Organismo de financiamiento",
		//***Financiamiento de viviendas
		"Financiamiento público de viviendas",
		"Jurisdicción","Organismo de financiamiento",
		//***Financiamiento de Equipamientos Comunitarios
		"Financiamiento público de equipamientos comunitarios",
		"Jurisdicción","Organismo de financiamiento",
		//***Plano de mensura y división
		"Plano - estado de la tramitación",
		"Región"
	]);*/

	//crear Header Analitico
	/*const crearHeaderAnalitico = (columnas)=>{
		if(columnasAnalitico !== null){
			return columnas.map((columna, index) => {
				return <th key={index} >{columna}</th>
			});
		}
	}*/

	/*const crearRowsDataAnalitico = (datosA)=>{
		if(datosA !==null){
			return datosA.map((registro, index)=>{

				var id_1CSP;
				var id_2CSP;
				var id_3CSP;
				var id_4CSP;//agregar 6 nuevas columnas - 12jul2023
				var id_5CSP;
				var id_8CSP;
				var id_9CSP;
				var id_10CSP;
				var id_13CSP;
				var id_14CSP;
				var id_15CSP;
				var id_18CSP;
				
				var array = registro.array_csp;
				array.map((item, index)=>{
					id_1CSP = item.csp_1_tamaño_medio_lotes_residenciales;
					id_2CSP = item.csp_2_actor_encargado_obras_infra;
					id_3CSP = item.csp_3_financiamiento_publico_obras_infra;
					id_4CSP = item.csp_4_jurisdiccion_infra;
					id_5CSP = item.csp_5_organismo_financiamiento_infra;
					id_8CSP = item.csp_8_financiamiento_publico_obras_viv
					id_9CSP = item.csp_9_jurisdiccion_viv;
					id_10CSP = item.csp_10_organismo_financiamiento_viv;
					id_13CSP = item.csp_13_financiamiento_publico_obras_equip;
					id_14CSP = item.csp_14_jurisdiccion_equip;
					id_15CSP = item.csp_15_organismo_financiamiento_equip;
					id_18CSP = item.csp_18_pl_mensura_estado;
				});

				var id_AN = registro.id;

				var id_1AN = registro.alias; //registro.nombre_proyecto;
				var id_2AN = registro.expediente;
				var id_3AN = registro.programa;
				var id_4AN = registro.tipo;
				var id_5AN = registro.partido_nombre;
				var id_6AN = registro.cantidad_lotes;
				var id_7AN = registro.cantidad_lotes_adjudicables;
				var id_8AN = registro.porc_lotes_municipales;
				var id_9AN = registro.lotes_adquiridos_municipio;
				var id_10AN = registro.sistema_agua;
				var id_11AN = registro.eliminacion_excretas;
				var id_12AN = registro.año_emision;
				var id_13AN = registro.periodo;
				var id_14AN = registro.region ;
				var id_15AN = registro.datos_18_sintesis_nomenclatura;

				return <tr id={id_AN} >
					<td>{id_1AN}</td><td>{id_2AN}</td><td>{id_3AN}</td>
					<td>{id_4AN}</td><td>{id_5AN}</td><td>{id_6AN}</td>
					<td>{id_7AN}</td><td>{id_8AN}</td><td>{id_9AN}</td>
					<td>{id_15AN}</td>
					<td>{id_10AN}</td><td>{id_11AN}</td><td>{id_12AN}</td>
					<td>{id_13AN}</td>

					<td>{id_1CSP}</td><td>{id_2CSP}</td>
					<td>{id_3CSP}</td><td>{id_4CSP}</td><td>{id_5CSP}</td>
					<td>{id_8CSP}</td><td>{id_9CSP}</td><td>{id_10CSP}</td>
					<td>{id_13CSP}</td><td>{id_14CSP}</td><td>{id_15CSP}</td>
					<td>{id_18CSP}</td>

					<td>{id_14AN}</td>
				</tr>;
			})
		}
	}*/

	/*const crearRowsDataMadre = (datosT)=> {
		if(datosT !== null){
			return datosT.map((registro, index )=> {
				var id_1CSP;
				var id_2CSP;
				var id_3CSP;
				var id_4CSP;
				var id_5CSP;
				var id_6CSP;
				var id_7CSP;
				var id_8CSP;
				var id_9CSP;
				var id_10CSP;
				var id_11CSP;
				var id_12CSP;
				var id_13CSP;
				var id_14CSP;
				var id_15CSP;
				var id_16CSP;
				var id_17CSP;
				var id_18CSP;
				var id_19CSP;
				var id_20CSP;
				var id_21CSP;
				var id_22CSP;
				var id_23CSP;
				var id_24CSP;
				var id_25CSP;
				var id_26CSP;
				var id_27CSP;

				var array = registro.array_csp;

				array.map((item, index)=>{
					id_1CSP = item.csp_1_tamaño_medio_lotes_residenciales;
					id_2CSP = item.csp_2_actor_encargado_obras_infra;
					id_3CSP = item.csp_3_financiamiento_publico_obras_infra;
					id_4CSP = item.csp_4_jurisdiccion_infra;
					id_5CSP = item.csp_5_organismo_financiamiento_infra;
					id_6CSP = item.csp_6_fecha_control_infra;
					id_7CSP = item.csp_7_descripcion_obras_infra;
					id_8CSP = item.csp_8_financiamiento_publico_obras_viv;
					id_9CSP = item.csp_9_jurisdiccion_viv;
					id_10CSP = item.csp_10_organismo_financiamiento_viv;
					id_11CSP = item.csp_11_fecha_control_viv;
					id_12CSP = item.csp_12_descripcion_obras_viv;
					id_13CSP = item.csp_13_financiamiento_publico_obras_equip;
					id_14CSP = item.csp_14_jurisdiccion_equip;
					id_15CSP = item.csp_15_organismo_financiamiento_equip;
					id_16CSP = item.csp_16_fecha_control_equip;
					id_17CSP = item.csp_17_descripcion_obras_equip;
					id_18CSP = item.csp_18_pl_mensura_estado;
					id_19CSP = item.csp_19_pl_mensura_partido;
					id_20CSP = item.csp_20_pl_mensura_orden;
					id_21CSP = item.csp_21_pl_mensura_año;
					id_22CSP = item.csp_22_pl_mensura_fecha;
					id_23CSP = item.csp_23_registracion_legajo;
					id_24CSP = item.csp_24_registracion_folio;
					id_25CSP = item.csp_25_registracion_fecha;
					id_26CSP = item.csp_26_fecha_control;
					id_27CSP = item.csp_27_observaciones_csp;
				})

				var id_TR = registro.id;
				var id_1T = registro.nombre_proyecto;
				var id_2T = registro.expediente;
				var id_3T = registro.fecha_carga;
				var id_4T = registro.fecha_caratulacion;
				var id_5T = registro.datos_05_responsable;
				var id_6T = registro.tramite_prioritario;
				var id_7T = registro.programa;
				var id_8T = registro.estado;
				var id_9T = registro.tipo;
				var id_10T = registro.partido_nombre;
				var id_11T = registro.localidad;
				var id_12T = registro.cantidad_lotes;
				var id_13T = registro.cantidad_lotes_adjudicables;
				var id_14T = registro.porc_lotes_municipales;
				var id_15T = registro.lotes_adquiridos_municipio;
				var id_16T = registro.sistema_agua;
				var id_17T = registro.eliminacion_excretas;
				var id_18T = registro.datos_18_sintesis_nomenclatura;
				var id_19T = registro.titular_tierra;
				var id_20T = registro.plano_oficial;
				var id_21T = registro.area; //area, zonificacion, nro_ordenanza_vigente, decreto_promulgatorio
				var id_22T = registro.zonificacion;
				var id_23T = registro.nro_ordenanza_vigente;
				var id_24T = registro.decreto_promulgatorio;
				var id_25T = registro.observaciones_generales;
				//solicitud_adhesion_t, certif_urbanistico_municipal_t, certif_no_inundabilidad_t, anteproyecto_urbano_t,
				var id_101T = registro.solicitud_adhesion_t;
				var id_102T = registro.certif_urbanistico_municipal_t;
				var id_103T = registro.certif_no_inundabilidad_t;
				var id_104T = registro.anteproyecto_urbano_t;
				var id_105T = registro.titulo_propiedad_t;
				//informe_dominio_t, anotaciones_personales_t, admisibilidad_inicial_t, prefactibilidad_hidraulica_t,prefactibilidad_urbanistica_t
				var id_106T = registro.informe_dominio_t;
				var id_107T = registro.anotaciones_personales_t;
				var id_108T = registro.admisibilidad_inicial_t;
				var id_109T = registro.prefactibilidad_urbanistica_t;
				var id_110T = registro.prefactibilidad_hidraulica_t;
				//memoria_descriptiva_obras_t, energia_electrica_t, agua_potable_t,eliminacion_excretas_t
				var id_111T = registro.memoria_descriptiva_obras_t;
				var id_112T = registro.energia_electrica_t;
				var id_113T = registro.agua_potable_t;
				var id_114T = registro.eliminacion_excretas_t;
				//apertura_calles_t, acta_constitutiva_t, certif_vigencia_persona_juridica_t, plano_mensura_t, convenio_urbanistico_t, ordenanza_municipal_t,
				//memoria_descriptiva_procedimientos_t,
				var id_115T = registro.apertura_calles_t;
				var id_116T = registro.acta_constitutiva_t;
				var id_117T = registro.certif_vigencia_persona_juridica_t;
				var id_118T = registro.plano_mensura_t;
				var id_119T = registro.convenio_urbanistico_t;
				var id_120T = registro.ordenanza_municipal_t;
				var id_121T = registro.memoria_descriptiva_procedimientos_t;
				var id_144T = registro.t_144_proyecto_hidraulico; //add 25-12-2022
				//certif_tecnico_urbanistico_t, resolucion_afectacion_t, visado_plano_t, observaciones_internas_t, observaciones_reporte_t
				var id_122T = registro.certif_tecnico_urbanistico_t;
				var id_123T = registro.resolucion_afectacion_t;
				var id_124T = registro.visado_plano_t;
				var id_125T = registro.observaciones_internas_t;
				var id_126T = registro.observaciones_reporte_t;
				//t_127_tramitacion_ada_prefac_h
				//fecha_envio_prefac_h_t, expediente_prefac_h_t, fecha_emision_prefac_h_t,
				//t_131_observaciones_prefact_h
				var id_127T = registro.t_127_tramitacion_ada_prefac_h;
				var id_128T = registro.fecha_envio_prefac_h_t;
				var id_129T = registro.expediente_prefac_h_t;
				var id_130T = registro.fecha_emision_prefac_h_t;
				var id_131T = registro.t_131_observaciones_prefact_h;
				// t_132_tramitacion_resol,fecha_envio_resol_t,fecha_emision_resol_t,numero_especial_resol_t,t_136_observaciones_resol
				var id_132T = registro.t_132_tramitacion_resol;
				var id_133T = registro.fecha_envio_resol_t;
				var id_134T = registro.fecha_emision_resol_t;
				var id_135T = registro.numero_especial_resol_t;
				var id_136T = registro.t_136_observaciones_resol;
				var id_137T = registro.t_137_tramitacion_visado;//t_137_tramitacion_visado, fecha_visado_plano_t
				var id_138T = registro.fecha_visado_plano_t;
				var id_139T = registro.t_139_fecha_entrega;
				var id_140T = registro.t_140_observaciones_tramitacion;

				var id_Region = registro.region;

				return <tr id={id_TR} >
						<td>{id_TR}</td>
						<td>{id_1T}</td><td>{id_2T}</td>
						<td>{id_3T}</td><td>{id_4T}</td>
						<td>{id_5T}</td><td>{id_6T}</td>
						<td>{id_7T}</td><td>{id_8T}</td>
						<td>{id_9T}</td><td>{id_10T}</td>
						<td>{id_11T}</td><td>{id_12T}</td>
						<td>{id_13T}</td><td>{id_14T}</td>
						<td>{id_15T}</td><td>{id_16T}</td>
						<td>{id_17T}</td><td>{id_18T}</td>
						<td>{id_19T}</td><td>{id_20T}</td>
						<td>{id_21T}</td><td>{id_22T}</td>
						<td>{id_23T}</td><td>{id_24T}</td>
						<td>{id_25T}</td>

						<td>{id_101T}</td><td>{id_102T}</td><td>{id_103T}</td><td>{id_104T}</td><td>{id_105T}</td>
						<td>{id_106T}</td><td>{id_107T}</td><td>{id_108T}</td><td>{id_109T}</td><td>{id_110T}</td>
						<td>{id_111T}</td><td>{id_112T}</td><td>{id_113T}</td><td>{id_114T}</td><td>{id_115T}</td>
						<td>{id_116T}</td><td>{id_117T}</td><td>{id_118T}</td><td>{id_119T}</td><td>{id_120T}</td>
						<td>{id_121T}</td> <td>{id_144T}</td> <td>{id_122T}</td><td>{id_123T}</td><td>{id_124T}</td><td>{id_125T}</td>
						<td>{id_126T}</td><td>{id_127T}</td><td>{id_128T}</td><td>{id_129T}</td><td>{id_130T}</td>
						<td>{id_131T}</td><td>{id_132T}</td><td>{id_133T}</td><td>{id_134T}</td><td>{id_135T}</td>
						<td>{id_136T}</td><td>{id_137T}</td><td>{id_138T}</td><td>{id_139T}</td><td>{id_140T}</td>

						<td>{id_1CSP}</td><td>{id_2CSP}</td><td>{id_3CSP}</td><td>{id_4CSP}</td><td>{id_5CSP}</td>
						<td>{id_6CSP}</td><td>{id_7CSP}</td><td>{id_8CSP}</td><td>{id_9CSP}</td><td>{id_10CSP}</td>
						<td>{id_11CSP}</td><td>{id_12CSP}</td><td>{id_13CSP}</td><td>{id_14CSP}</td><td>{id_15CSP}</td>
						<td>{id_16CSP}</td><td>{id_17CSP}</td><td>{id_18CSP}</td><td>{id_19CSP}</td><td>{id_20CSP}</td>
						<td>{id_21CSP}</td><td>{id_22CSP}</td><td>{id_23CSP}</td><td>{id_24CSP}</td><td>{id_25CSP}</td>
						<td>{id_26CSP}</td><td>{id_27CSP}</td>

						<td>{id_Region}</td>
					</tr>;
			})
		}
	}*/

	/*useEffect(()=>{
		//console.log("CAMBIO MUNICIPIO ---> carga inicial tabla madre");
		//llamarTablaMadre(columnasMadre); // COMENTAR 2-06-2022
	}, [municipioMadre]);

	useEffect(()=>{
		//llamarTablaMadre(columnasMadre); //AL CAMBIAR campos seleccionados // COMENTAR 2-06-2022
	}, [columnasMadre]);

	const llamarTablaMadre = async (parametros) => {
		//console.log("EJECUTAR FUNCTION");
		const param = parametros;
		const url = `${baseURL}api/reportes/reporte2_tablaMadre/?filterMunicipioMadre=${municipioMadre}&filterTablaMadre=${param}`;

		const headers = {
			method: 'get',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			jwt_token: localStorage.token
		};

		const res = await fetch(url,{ headers })
		.then(res => res.json())
		.then(res => setDatosMadre(res))
		.catch(err => console.log(err));
		//.finally(() => setLoading(false));

	}*/

	/*const crearButton = (campos) =>{ //crea los buttons, se le pasa array de objeto

		// **** https://atomizedobjects.com/blog/react/how-to-render-an-array-of-objects-with-map-in-react/
		return (
			<>
				{campos.map( ({campo, activo, label}, index) => ( //VER el index

					<Button variant={activo === 'true' ? 'primary' : 'secondary'} size="sm" name={campo} activo={activo} onClick={selectCampo}>
		      	#{index+1 } {label}
		      </Button>
				))}
			</>
		)
	}

	const selectCampo = (e) => {
		e.preventDefault();
		var campo = e.target.name;
		var activo = e.target.attributes.activo.value;
		//console.log(campo);
		//console.log(activo);
		//https://stackoverflow.com/questions/55987953/how-do-i-update-states-onchange-in-an-array-of-object-in-react-hooks
		if(activo==='false'){
			setCamposInicial(
				camposInicial.map(item => item.campo === campo ? {...item, activo:"true"} : item )
			)
		} else if (activo==='true'){
			setCamposInicial(
				//NOTA: cambiar solo donde coincida el nombre
				camposInicial.map(item => item.campo === campo ? {...item, activo:"false"} : item )
			)
		}
	}*/

	//***TODO PARA SEARCH***********
	//****************************04-05***CADA VEZ que CAMBIA arrayBusqueda

	//****************************************************************************************
	//NO BORRAR!!!
	//Se consultaban todos los datos y luego se filtraba usando expresiones regulares
	/*useEffect(()=>{
		//console.log("HACER BUSQUEDA CON MULTIPLES VARIABLES"); //sobre datos Originales

		if(datosS !== null){

			const regexExp = new RegExp(arrayBusqueda.expB, 'i');
			//console.log(regexExp);
			//const REGEX = /a|b/g;
			//const REGEX = /[0-59]/g;
			//console.log(REGEX);

			var rxPartido = /\d{1}/ ;
			//console.log(arrayBusqueda.partidoB);
			//console.log(typeof(arrayBusqueda.partidoB));

			var xF = parseInt(arrayBusqueda.partidoB);
			//console.log(typeof(xF));
			//console.log(`${xF}`);
			//console.log(xF);
			var zF = `${xF}`;

			//const regexPartido = new RegExp(arrayBusqueda.partidoB{2});
			const uno = /^d/;
			//console.log(uno);
			//const regexPartido = new RegExp(/^`${arrayBusqueda.partidoB}/);
			//const regexPartido = new RegExp(arrayBusqueda.partidoB); //  /^d/
			var regexPartido =  new RegExp(`^${arrayBusqueda.partidoB}{1,3}`); //   /^d{1,3}/ ;
			//let uno = new RegExp(`^${arrayBusqueda}{${nro}}`);


			if(xF === 0 ){
				regexPartido = /./;
			} else if(xF > 0 && xF < 10){
				//console.log("PARA Alsina");
				const arrayB1 = `${arrayBusqueda.partidoB}`;
				regexPartido = new RegExp(`^${arrayB1}{1,3}`);
			} else if(xF > 9 && xF < 99) {
				//console.log("PARA Benito Juarez");
				const arrayB2 = `${arrayBusqueda.partidoB}`;
				regexPartido =  new RegExp(`^${arrayB2}{1,3}`);
			} else {
				regexPartido = new RegExp(`^${arrayBusqueda.partidoB}{1,3}`);
			}

			//console.log(regexPartido);


			//const rxPartido = /\arrayBusqueda.partidoB{2}/;
			//const regexPartido =  /[0-9]{1,3}/ ; //     /\d{1,3};
			//const regexPartido = /\d{1,3}/g;
			//const partidoBusqueda = arrayBusqueda.partidoB

			//const regexPartido = /partidoBusqueda/;

			const regexEstado = new RegExp(arrayBusqueda.estadoB, 'i');
			//console.log(regexEstado);
			const regexAlias= new RegExp(arrayBusqueda.aliasB, 'i');
			//console.log(regexAlias);
			const regexPrograma= new RegExp(arrayBusqueda.programaB, 'i');
			//console.log(regexPrograma);
			const regexTipo= new RegExp(arrayBusqueda.tipoB, 'i');
			//console.log(regexTipo);
			const regexTitular= new RegExp(arrayBusqueda.titularB, 'i');
			//console.log(regexTitular);


			const filtradoP = datosOriginal.filter((item, index) =>{

				//VERIFICAR solo donde sean iguales para evitar que tome por ejemplo 1 para (todos los que empiezan con 1)1, 11, 120....
				if(parseInt(arrayBusqueda.partidoB) ===0){
					//console.log("PARTIDO es VALUE CERO _____  DENTRO del primer IF");
					//console.log(regexPartido.test(item.partido));
					//console.log(/./.test(item.partido));

					return regexExp.test(item.expediente) && /./.test(item.partido) && regexEstado.test(item.estado) && regexAlias.test(item.alias)  && regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexTitular.test(item.titular_tierra)

				} else if(item.partido === parseInt(arrayBusqueda.partidoB)){

					//console.log("partido y array son iguales");
					//console.log(item.partido);
					//console.log(parseInt(arrayBusqueda.partidoB));
					//console.log(regexPartido.test(item.partido));

					//console.log(/arrayBusqueda.partidoB{2}/.test(item.partido));
					//console.log(rxPartido.test(item.partido));
					return regexExp.test(item.expediente) && regexPartido.test(item.partido) && regexEstado.test(item.estado) && regexAlias.test(item.alias)  && regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexTitular.test(item.titular_tierra)
					//return regexExp.test(item.expediente) && /arrayBusqueda.partidoB{2}/.test(item.partido) && regexEstado.test(item.estado) && regexAlias.test(item.alias)  && regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexTitular.test(item.titular_tierra)

				}
			})
			//console.log(filtradoP);
			setDatos(filtradoP);
			setDatosMadre(filtradoP);
			//setDatosOriginales(filtradoP)
		}

	},[arrayBusqueda]);*/
	//*****************************************************************


	//***Estado***
	const handleOnChangeEstado = (e) => {

		if(e.value){
			if(e.value !== null){
				setSearchEstado(e.value);
				//setArrayBusqueda({...arrayBusqueda,  "estadoB":e.value} );
				setFiltro({...filtro,  "estado":e.value} );
				//console.log("AAA");
			}
		} else if( e.value === ""){
			setSearchEstado(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			//setArrayBusqueda({...arrayBusqueda,  "estadoB":e.value} );
			setFiltro({...filtro,  "estado":e.target.value} )
			//console.log("BBB");

		} else if (e.target.value){
			if(e.target.value !== null){
				setSearchEstado(e.target.value);
				//setArrayBusqueda({...arrayBusqueda,  "estadoB":e.target.value} )
				setFiltro({...filtro,  "estado":e.target.value} )
				/*if(e.target.value === "AFECTADO CON RESOLUCIÓN" || e.target.value === "EN PROCESO"){
					setInputDisable(false);
				} else {
					setInputDisable(true);
				}*/
				//console.log("CCC");
			}
		} else {
			//console.log("____SELECCIONADO SIN VALOR");
			setSearchEstado(/./);
			//setArrayBusqueda({...arrayBusqueda,  "estadoB":""} )
			setFiltro({...filtro,  "estado":""} )
			//console.log("DDD");
		}
	}
	//---Estado---

	//***Partido***
	const handleOnChangePartido = (e) => {

		if(e.value){
			//console.log(e.value);

			if(e.value !== null){
				setSearchPartido(e.value);
				//setArrayBusqueda({...arrayBusqueda,  "partidoB":e.value} )
				setFiltro({...filtro,  "partido":e.value} )
			}
		} else if( e.value === ""){
			setSearchPartido(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			//setArrayBusqueda({...arrayBusqueda,  "partidoB":e.value} )
			setFiltro({...filtro,  "partido":e.value} )

		} else if (e.target.value){
			//console.log(e.target);
			if(e.target.value !== null){
				//console.log("VALUE PARTIDO");
				//console.log(e.target.value);
				if(e.target.value === 0){
					setSearchPartido(e.target.value);
					//setArrayBusqueda({...arrayBusqueda,  "partidoB":0} )
					setFiltro({...filtro,  "partido":0} )
				} else {
					setSearchPartido(e.target.value);
					//setArrayBusqueda({...arrayBusqueda,  "partidoB":e.target.value} )
					setFiltro({...filtro,  "partido":e.target.value} )
				}

			}
		} else {
			setSearchPartido(/./);
			//setArrayBusqueda({...arrayBusqueda,  "partidoB":0} )
			setFiltro({...filtro,  "partido":0} )
		}
	}
	//---Partido---

	//***Programa*** //ver diferencia si usa <select> o <SelectInput>
	const handleOnChangePrograma = (e) => {
		if(e.value){
			if(e.value !== null){
				//setSearchPrograma(e.value);
				//setArrayBusqueda({...arrayBusqueda,  "programaB":e.value} )
				setFiltro({...filtro,  "programa":e.value} )
			}
		} else if( e.value === ""){
			//setSearchPrograma(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			//setArrayBusqueda({...arrayBusqueda,  "programaB":e.value} )
			setFiltro({...filtro,  "programa":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					setSearchPrograma(e.target.value);
					//setArrayBusqueda({...arrayBusqueda,  "programaB":""} )
					setFiltro({...filtro,  "programa":""} )
				} else {
					setSearchPrograma(e.target.value);
					//setArrayBusqueda({...arrayBusqueda,  "programaB":e.target.value} )
					setFiltro({...filtro,  "programa":e.target.value} )
				}
			}
		} else {
			setSearchPrograma(/./);
			//setArrayBusqueda({...arrayBusqueda,  "programaB":""} )
			setFiltro({...filtro,  "programa":""} )
		}
	} //---cierra handleOnChangePrograma -----

	//***Tipo***
	const handleOnChangeTipo = (e) => {

		if(e.value){
			if(e.value !== null){
				setSearchTipo(e.value);
				//setArrayBusqueda({...arrayBusqueda,  "tipoB":e.value} )
				setFiltro({...filtro,  "tipo":e.value} )
			}
		} else if( e.value === ""){
			setSearchTipo(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			//setArrayBusqueda({...arrayBusqueda,  "tipoB":e.value} )
			setFiltro({...filtro,  "tipo":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					setSearchTipo(e.target.value);
					//setArrayBusqueda({...arrayBusqueda,  "tipoB":""} )
					setFiltro({...filtro,  "tipo":""} )
				} else {
					setSearchTipo(e.target.value);
					//setArrayBusqueda({...arrayBusqueda,  "tipoB":e.target.value} )
					setFiltro({...filtro,  "tipo":e.target.value} );
				}

			}
		} else {
			setSearchTipo(/./);
			//setArrayBusqueda({...arrayBusqueda,  "tipoB":""} )
			setFiltro({...filtro,  "tipo":""} )
		}
	}

	//***Regione
	const handleOnChangeRegion = (e) => {

		if(e.value){
			if(e.value !== null){
				setSearchRegion(e.value);
				setFiltro({...filtro,  "region":e.value} )
			}
		} else if( e.value === ""){
			setSearchRegion(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setFiltro({...filtro,  "region":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					setSearchRegion(e.target.value);
					setFiltro({...filtro,  "region":""} )
				} else {
					setSearchRegion(e.target.value);
					setFiltro({...filtro,  "region":e.target.value} );
				}

			}
		} else {
			setSearchRegion(/./);
			setFiltro({...filtro,  "region":""} )
		}
	}
	//----

	//****Responsable
	const handleOnChange_Responsable = (e) => {
		
		if(e.value){ //** NOTA: entrá acá en algún momento????

			if(e.value !== null){
				//setSearchResponsable(e.value); //** QUITAR?? 0_o 26jun24
				setFiltro({...filtro,  "datos_05_responsable": e.value} )
			}
		} else if( e.value === ""){
			//setSearchResponsable(/./); //** QUITAR 0_o 26jun24
			setFiltro({...filtro,  "datos_05_responsable": e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					//setSearchResponsable(e.target.value);
					setFiltro({...filtro,  "datos_05_responsable": ""} )
				} else {
					//setSearchResponsable(e.target.value);
					setFiltro({...filtro,  "datos_05_responsable": e.target.value} )
				}
			}
		} else {
			//setSearchResponsable(/./);
			setFiltro({...filtro,  "datos_05_responsable":""} )
		}
	}	
	//---- cierra R_esponsable	

	const getHeader = (columnas)=>{

			return columnas.map((columna, index) => {

				if (columna === "Partido") {
					return <th key={index} >
						{columna}
					</th>
				} else if (columna === "Expediente"){
					//return <th key={index} > {columna}<input id="search" type="text" name="search" onChange={handleSearch} value={search || ''} /> </th>
					return <th key={index} > {columna} </th>
				} else if (columna === "Estado") {
					return <th key={index} >
						{columna}
					</th>
				} else if (columna === "Alias") {
					//return <th key={index} > {columna}<input id="searchA" type="text" name="alias" onChange={handleSearchAlias} value={searchAlias || ''} /> </th>
					return <th key={index} style={{ width:"100px"}}> {columna} </th>
				} else if (columna === "Cantidad lotes"){
					return <th key={index} > {columna} </th>
				} else if (columna === "Programa") {
					return <th key={index} >
						{columna}
					</th>
				} else if (columna === "Tipo") {
					return <th key={index} >
						{columna}
					</th>
				} else {
					return <th key={index} >{columna}</th>
				}
			})		
	}

	/*const getRowsData = (datosS)=> {
		//console.log("EJECUTAR AL INICIO -- REPORTE 3");
		if(datosS !== null){

			return datosS.map((registro, index )=> {
				//var partido = registro.partido_nombre;
				var expediente = registro.expediente;
				var estado = registro.estado;
				var nombre_proyecto = registro.nombre_proyecto;//nombre_proyecto
				var cantidad_lotes = registro.cantidad_lotes;
				var programa = registro.programa;
				var tipo = registro.tipo;
				//var titular = registro.titular_tierra;
				//var lotes_captados_municipio = registro.lotes_captados_municipio;
				var nomenclatura_catastral = registro.datos_18_sintesis_nomenclatura;

				return <tr id={index}> 
					<td>{registro.partido_nombre}</td>
					<td>{expediente}</td>
					<td>{estado}</td>
					<td>{nombre_proyecto}</td>
					<td>{cantidad_lotes}</td>
					<td>{programa}</td>
					<td>{tipo}</td>
					<td>{nomenclatura_catastral}</td>
				</tr>;
			})

		}
	}*/

	const [categoriaSelect, setCategoriaSelect] = useState('AA');

	const onChangeAA = (e) =>{
        console.log("select");
		const {name, value, type} = e.target;

		if(value === 'AA'){
			setCategoriaSelect('AA')
		} else {
			setCategoriaSelect('BB')
		}
    }

	const optionsCategoria = [
        {value: "AA", label: "Programa"},
        {value: "BB", label: "Estado"}
    ]


	if (datosS === null) {
		
		return(
			<Fragment>
				<p>INICIO hacer consulta</p>
				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className="">{/*border:"2px solid blue"*/}
					<div className="" style={{ width:"100%", overflow:"scroll"}} >
						<TablaInf2_Seleccionados datosS={datosS} columnas={columnas}/>
					</div>
				</div>
			</Fragment>
		)
	} else {
		//console.log("YA NO ES NULL");
		return (
			<Fragment>

				<div id="reporte2_A1" className="container_row">

					<div className="container_inicio">
						<div style={{ alignItems: "right", height: "40px", width: "95%" }} > </div>
						<div className="input-container">
							<SelectInput
								name="estado"
								id="id_estado"
								options={optionsEstado_Todos}
								onChange={e => handleOnChangeEstado(e)}
								//value={optionsEstado.value}
								defaultValue="AFECTADO CON RESOLUCIÓN"
							/>
							<label alt="Estado" for="id_estado">Estado</label>
						</div>
					</div>

					<div className="" style={{ width: "1%" }} />

					<div className="container_inicio">
						<div style={{ alignItems: "right", width: "95%" }} >
							<p className="subtitulos">Desde</p>
						</div>
						<div className="input-container">
							<DatePicker
								name="intervalo_fecha"
								selected={_fechaInicio}
								className="form-control"
								id="fecha_desde"
								onChange={(date) => setStartDate(date)}
								startDate={startDate}
								locale="es-AR"
								dateFormat="MM/yyyy"
								isClearable={false}
								required
							/>
						</div>
					</div>

					<div className="" style={{ width: "1%" }} />

					<div className="container_inicio">
						<div style={{ alignItems: "right", width: "95%" }} >
							<p className="subtitulos">Hasta</p>
						</div>
						<div className="input-container">
							<DatePicker
								name="intervalo_fecha"
								selected={ultimoDia}
								className="form-control"
								id="fecha_desde"
								onChange={(date) => setEndDate(date)}
								endDate={ultimoDia}
								locale="es-AR"
								dateFormat="MM/yyyy"
								isClearable={false}
								required
							/>
						</div>
					</div>

					<div className="" style={{ width: "1%" }} />
					<div className="container_inicio">
						<div style={{ alignItems: "right", height: "40px", width: "95%" }} ></div> {/* border:"2px solid blue" */}
						<div className='input-container'>
							<SelectInput
								name="datos_05_responsable"
								id="id_05_responsable"
								options={optionsResponsable_Todos}
								onChange={e => handleOnChange_Responsable(e)}
								defaultValue=""
							/>{/* NUEVO select para responsable */}
							<label alt="Responsable" for="id_05_responsable">Responsable</label>
						</div>						
					</div>

				</div>

				<div className="container_left">

					<div className="input-container" style={{width: "25%"}}>
						<SelectInput
							name="partido"
							id="id_partido"
							options={ optionsRP2_Todos }
							onChange={e=>handleOnChangePartido(e)}
						/>
						<label alt="Partido" for="id_partido">Partido</label>
					</div>
					<div className="" style={{width: "1%"}} />
					<div className="input-container" style={{width: "25%"}}>
						<SelectInput
							name="programa"
							id="id_programa"
							options={optionsPrograma_Todos}
							onChange={e => handleOnChangePrograma(e)}
						/>
						<label alt="Programa" for="id_programa">Programa</label>
					</div>
					<div className="" style={{width: "1%"}} />
					<div className="input-container" style={{width: "25%"}}>
						<SelectInput
							name="tipo"
							id="id_tipo"
							options={optionsTipo_Todos}
							onChange={e => handleOnChangeTipo(e)}
						/>
						<label alt="Tipo" for="id_tipo">Tipo</label>
					</div>
					<div className="" style={{width: "1%"}} />
					<div className="input-container" style={{width: "25%"}}>
						<SelectInput
							name="region"
							id="id_region"
							options={optionsRegiones_todos}
							onChange={e => handleOnChangeRegion(e)}
						/>
						<label alt="Regiones" for="id_region">Categorías de partidos según tamaño</label>
					</div>

				</div>

				<div id="id_tablas" className="container_row">
					<div id="informes_col2" className="container_resumen_rp2">{/*columna1*/}
						<TablaInf2_Detallado datos_Inf2_finalizadosTotal={datos_Inf2_finalizadosTotal}/>						
					</div>

					
					<div className="container_resumen_rp2">
						<div style={{color: 'white'}}>
							<h2>M</h2>
							<Button form='my-form' type="button" className="btn btn-info btn-lg text_button buttonVerMapa" onClick={buttonClick}>Ver mapa</Button>
						</div>
					</div>					
				</div>

				<div id="reporte2_3button" className="container_row_hide">
					<div className="container_inicio">
						<ReactHTMLTableToExcel
							id="test-table-xls-button" className="btn btn-info text_button" table="table-madre-to-xls"
							filename="informe_todos_los_campos" sheet="tablexls" buttonText="Descargar XLS"
						/>
					</div>
					<div className="container_inicio">
							<ReactHTMLTableToExcel
								id="uno-table-xls-button" className="btn btn-info text_button" table="table-seleccionados-to-xls"
								filename="informe_campos_seleccionados" sheet="tablexls" buttonText="Descargar XLS"
							/>
					</div>
					<div className="container_inicio">
						<ReactHTMLTableToExcel
							id="analitico-table-xls-button" className="btn btn-info text_button" table="table-analitico-to-xls"
							filename="reporte_analítico" sheet="tablexls" buttonText="Descargar XLS"
						/>
					</div>
					<div className="container_inicio">
						<ReactHTMLTableToExcel
							id="actuaciones-table-xls-button" className="btn btn-info text_button" table="table-actuaciones-to-xls"
							filename="estado_actuaciones" sheet="tablexls" buttonText="Descargar XLS"
						/>
					</div>					
				</div>{/* QUEDA hide*/}

				{/* NUEVO ----> Para los Buttons de Descarga (C)*/}
				<div id="reporte2_3nuevoButton" className="container_row">
					<div className="container_inicio">
						<div><h6 className="mt-4 text-left subtitulos">Informe (todos los campos)</h6></div>
						<div>
							<Button form='my-form' className="btn btn-info text_button" type="button" onClick={loadMadre}><FaFileDownload /></Button>
						</div>
					</div>

					<div className="container_inicio">
						<div><h6 className="mt-4 text-left subtitulos">Informe (campos seleccionados)</h6></div>
						<div>
							<Button form='my-form' className="btn btn-info text_button" type="button" onClick={loadSeleccionados}><FaFileDownload /></Button>
						</div>
					</div>

					<div className="container_inicio">
						<div><h6 className="mt-4 text-left subtitulos">Reporte analítico</h6></div>
						<div>
							<Button form='my-form' className="btn btn-info text_button" type="button" onClick={loadAnalitico}><FaFileDownload /></Button>
						</div>
					</div>

					<div className="container_inicio">
						<div><h6 className="mt-4 text-left subtitulos">Estado actuaciones</h6></div>
						<div>
							<Button form='my-form' className="btn btn-info text_button" type="button" onClick={loadActuaciones}><FaFileDownload /></Button>
						</div>
					</div>

					<div className="container_inicio">
						<div><h6 className="mt-4 text-left subtitulos">Descargar GeoJSON</h6></div>
						<div>
							<button type="button" className="btn btn-info text_button"onClick={exportData}><FaFileDownload /></button>
						</div>
					</div>
				</div>
				<div className="container_row"></div> {/* QUEDA vacio para mostrar un espacio */}			

				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className=""> {/*, border:"2px solid blue" */}
					<div className="" style={{ width:"100%", overflow:"scroll"}} > {/* border:"2px solid red",*/}						
						<TablaInf2_Seleccionados datosS={datosS} columnas={columnas}/>
					</div>
				</div>

				<div className="container_principal"> 
					<div>

						<div>
							<TablaInf2_Madre datosMadre={datosMadre} columnas={columnasMadre}/>
						</div>

						<div>
							<TablaInf2_Analitico datos_Inf2_Analitico={datos_Inf2_Analitico} columnas={columnasAnalitico}/>
						</div>

						<div>
							<TablaInf2_Actuaciones datos_Inf2_Actuaciones={datos_Inf2_Actuaciones} columnas={columnasActuaciones}/>
						</div>

					</div>
				</div>
				<Modal
					id="id_modal_map"
					show={showDraw}
					//onHide={handleClose}
					backdrop="static"
					keyboard={false}
					className="id_modal_map"
					style={{top: "200"}}
					size="fullscreen"
				>
					<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} >
						<Modal.Title>
							Generación de suelo urbano {/*id_exp_pruebaE*/}							
						</Modal.Title>
						<SelectInput  className="leaflet-control" style={{zIndex : 2001}} onChange={e => onChangeAA(e)} options={optionsCategoria}/>
					</Modal.Header>
					<Modal.Body>
						
						<Map_View_Expedientes categoriaSelect={categoriaSelect} partido={partido} circ='00' secc='00' datosS={datosS}/>
					</Modal.Body>
					<Modal.Footer>
						<Button className="btn btn-success btn-block button_verde" onClick={guardarEditRegistro} disabled="">Salir</Button>						
					</Modal.Footer>
				</Modal>				

			</Fragment>
		);
	}
}

export default SelectInf_Detallado;

//05-08-2022: QUITAR
//`buscar: Partido:{partido || '____'}--Estado:{estado  || '____'} -- priorit:{prioritario  || '____'}-- Programa:{programa || '____'}--  Tipo:{tipo  || '____'}-- `
